import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { InitiateCSVUploadRequestDto, UploadDto, UploadUrlResponseDto, } from "./dtos";

export class SMPApi {
    private readonly httpClient: AxiosInstance;

    constructor(token?: string) {
        const axiosConfig: AxiosRequestConfig = {
            responseType: "json",
            baseURL: process.env.REACT_APP_API_URL
        };

        if (token) {
            axiosConfig.headers = {
                Authorization: `Bearer ${token}`
            };
        }

        this.httpClient = axios.create(axiosConfig);
    }

    async getUploads(): Promise<UploadDto[]> {
        const response = await this.httpClient.get<UploadDto[]>("/admin/uploads");
        return response.data;
    }

    async getUploadById(uploadId: string): Promise<UploadDto> {
        const response = await this.httpClient.get<UploadDto>(
            `/admin/uploads/${uploadId}`
        );
        return response.data;
    }

    async uploadFile(file: File, gbc: string): Promise<void> {
        const req: InitiateCSVUploadRequestDto = {
            gbc: gbc,
            fileName: file.name
        };
        const uploadResponse = await this.requestUploadUrl(req);

        const axiosConfig: AxiosRequestConfig = {
            responseType: "json",
            baseURL: process.env.REACT_APP_API_URL,
            method: uploadResponse.httpMethod,
            url: uploadResponse.uploadURL,
            data: file,
            headers: { "Content-Type": file.type },
        };
        const httpClient = axios.create();
        await httpClient.request(axiosConfig);
    }

    async deleteUploadFile(upload: UploadDto): Promise<void> {
        await this.httpClient.delete<void>(
            `/admin/uploads/${upload.id}/mappings`
        );
    }

    async requestUploadUrl(
        req: InitiateCSVUploadRequestDto
    ): Promise<UploadUrlResponseDto> {
        const response = await this.httpClient.post<UploadUrlResponseDto>(
            "/admin/initiate-upload",
            req
        );
        return response.data;
    }
}

export const smpApi = new SMPApi(); // TODO: use dependency injection
