import React, { useState } from 'react';
import logo from '../logo.png';
import '../style/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'oidc-react';

export const DashboardHeader = () => {

    const [userName, setUserName] = useState('');
    const authContext = useAuth();
    authContext?.userManager?.getUser().then(_ => {
        setUserName(_?.profile.name ?? '');
    });

    function logout(): void {
        authContext?.signOutRedirect();
    }

    return (
        <header className='header'>
            <div className='header-inner'>
                <div className='user'>
                    <span className='loggedinUser'>Eingeloggt als: {userName}</span>
                    <span className='logoutBtn' onClick={logout}><FontAwesomeIcon icon={faSignOutAlt}
                                                                                  title='{children}'/></span>
                </div>
                <img className='logo' src={logo} alt=''/>
            </div>
        </header>
    );
};
