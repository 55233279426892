import React from 'react';
import { DashboardPage } from './pages/DashboardPage';
import Store from './state/Store';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import { Route, Switch } from 'react-router';

const App = () => {
    const indexOfLastSlash = window.location.href.lastIndexOf('/');
    const baseUrl = window.location.href.substring(0, indexOfLastSlash);

    const authProviderSettings: Partial<AuthProviderProps> = {
        authority: process.env.REACT_APP_AUTHORITY,
        clientId: process.env.REACT_APP_CLIENTID,
        redirectUri: `${baseUrl}`,
        scope: 'openid profile',
        responseType: 'code',
        automaticSilentRenew: true
    }

    return (
        <Store>
            <AuthProvider {...authProviderSettings}>
                <Switch>
                    <Route path='/'>
                        <DashboardPage/>
                    </Route>
                </Switch>
            </AuthProvider>
        </Store>
    );
}

export default App;
