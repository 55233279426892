import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { DashboardHeader } from '../components/DashboardHeader';
import { UploadDto } from '../api/dtos';
import { UploadList } from '../components/UploadList';
import { SMPApi } from '../api/SMPApi';
import { AppContext } from '../state/Store';
import { useAuth } from 'oidc-react';
import DisplayError from '../components/DisplayError';
import Spinner from '../components/Spinner';
import jwt_decode from "jwt-decode";

export const DashboardPage: React.FC = () => {
    const authContext = useAuth();
    const [token, setToken] = useState('');
    const [appState, dispatch] = useContext(AppContext);

    authContext?.userManager?.getUser()
        .then(user => {
            if (!user?.access_token) return;
            if (appState.auth === "INIT")
            {
                console.log("TOKEN: " + user?.access_token);
                let decoded : any = jwt_decode(user?.access_token);
                let isAuthorized = decoded?.roles?.includes("csv_manager");
                console.log("DECODED: " + JSON.stringify(decoded));
                dispatch({
                    type: 'AUTHORIZE_USER',
                    data: isAuthorized ? "AUTHORIZED" : "FORBIDDEN"
                });
            }
            setToken(user.access_token);
        });
    const smpApi = new SMPApi(token);
    const requestFilePollInterval = 2000;
    
    const fetchData = async () => {
        dispatch({ type: 'LOAD_DATA' },);
        try {
            const result = await smpApi.getUploads();
            result.sort((a, b) => a.name > b.name ? 1 : -1);
            dispatch({ type: 'DATA_LOADED', data: result },);
        } catch (e : any) {
            console.error('LOADING_DATA_FAILED', e);
            dispatch({ type: 'LOADING_DATA_FAILED', error: e },);
        }
    };

    const deleteUpload = async (upload: UploadDto) => {
        const isConfirmed = window.confirm(`Soll ${upload.originalFileName} wirklich gelöscht werden?`);

        if (!isConfirmed) return;

        try {
            dispatch({ type: 'DELETING' });
            await smpApi.deleteUploadFile(upload);

            const pollFileStatus = async () => {
                const response = await smpApi.getUploadById(upload.id);

                if (response.status === 'DELETE_COMPLETE') {
                    dispatch({ type: 'DELETE_COMPLETE', data: upload });
                } else {
                    setTimeout(pollFileStatus, requestFilePollInterval);
                }
            };

            await pollFileStatus();
        } catch (e : any) {
            console.error('DELETE_FAILED', e);
            dispatch({ type: 'DELETE_FAILED', error: e },);
        }
    };

    const validateUploadSuccessfull = (upload: UploadDto) => {
        try {
            const pollFile = async () => {
                const response = await smpApi.getUploadById(upload.id);

                if (!appState.dashboard.isUploading && response.status === 'IMPORT_COMPLETE') {
                    dispatch({ type: 'UPLOAD_SUCCESS', data: response });
                } else {
                    setTimeout(pollFile, requestFilePollInterval);
                }
            };

            setTimeout(pollFile, requestFilePollInterval);
        } catch (e : any) {
            console.error('UPLOAD_FAILED', e);
            dispatch({ type: 'UPLOAD_FAILED', error: e });
        }
    }

    const uploadCSV = async (file: File, upload: UploadDto) => {
        try {
            dispatch({ type: 'START_UPLOAD', currentUploadGbc: upload.name });

            const pollFileStatus = async () => {
                const response = await smpApi.getUploadById(upload.id);

                if (response.status !== 'IMPORTING'
                    && response.status !== 'DELETING'
                    && response.status !== 'UPLOADING') {
                    await smpApi.uploadFile(file, upload.id);
                    validateUploadSuccessfull(upload);
                } else {
                    setTimeout(pollFileStatus, requestFilePollInterval);
                }
            };

            await pollFileStatus();
        } catch (e : any) {
            console.error('START_UPLOAD_FAILED', e);
            dispatch({ type: 'START_UPLOAD_FAILED', error: e },);
        }
    };

    useEffect(() => {
        if (!token) return;
        fetchData().then();
    }, [token]);

    return (
      <div>
        <DashboardHeader />

        {appState.auth === "AUTHORIZED" ? (
          <div className="container">
            <TitleStyled>Produktfamilie zu Artikelnummer Mapping</TitleStyled>

            {appState.error && (
              <DisplayError
                name={appState.error?.name}
                message={appState.error?.message}
              />
            )}

            <main className="container mt-5">
              {appState.dashboard.isLoading && <Spinner />}
              <UploadList
                uploads={appState.dashboard.data}
                onDeleteUpload={deleteUpload}
                onUploadFile={uploadCSV}
              />
            </main>
          </div>
        ) : (
          <div className="container">
              <DisplayError>
                <p>
                    Ihr Benutzer ist nicht für diese Anwendung freigeschaltet!<br />
                    Bitte wenden Sie sich an den <a href="mailto:assethub@sick.com?subject=Request for admin access to Product Family Mapper&body=me too">Administrator</a>.
                </p>
              </DisplayError>                
          </div>
        )}
      </div>
    );
};

const TitleStyled = styled.h1`
  margin: 20px 0 0;
  text-align: center;
`;




