import React from "react";
import { UploadDto } from "../api/dtos";

export type Action =
    | { type: "AUTHORIZE_USER"; data: "AUTHORIZED" | "FORBIDDEN" }
    | { type: "LOAD_DATA"; }
    | { type: "DATA_LOADED"; data: UploadDto[]; }
    | { type: "DELETING"; }
    | { type: "DELETE_COMPLETE"; data: UploadDto; }
    | { type: "DELETE_FAILED"; error: Error; }
    | { type: "LOADING_DATA_FAILED"; error: Error; }
    | { type: "START_UPLOAD"; currentUploadGbc: string; }
    | { type: "START_UPLOAD_FAILED"; error: Error, }
    | { type: "UPLOAD_SUCCESS"; data: UploadDto; }
    | { type: "UPLOAD_FAILED"; error: Error; };

export type AppState = {
    dashboard: {
        isLoading: boolean;
        isUploading: boolean;
        isDeleting: boolean;
        currentUploadGbc: string;
        data: UploadDto[]; // TODO add concrete type
        
    };
    auth: "INIT" | "AUTHORIZED" | "FORBIDDEN";
    error: any | null;
};

export const initialState: AppState = {
    dashboard: {
        isLoading: false,
        isUploading: false,
        isDeleting: false,
        currentUploadGbc: "",
        data: []        
    },
    auth: "INIT",
    error: null,
};

const mainReducer: React.Reducer<AppState, Action> = (
    prevState: AppState,
    action: Action
): AppState => {
    switch (action.type) {

        case "AUTHORIZE_USER":
            return {
                ...prevState,
                auth: action.data
            };

        // DATA
        case "LOAD_DATA":
            return {
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    isLoading: true,
                },
            };

        case "DATA_LOADED":
            return {
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    isLoading: false,
                    data: action.data,
                },
                error: null
            };

        case "DELETING":
            return {
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    isDeleting: true
                }
            }

        case "DELETE_COMPLETE":
            return {
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    isLoading: false,
                    isDeleting: false,
                    data: prevState.dashboard.data.map((uploadData: UploadDto) => {
                        if (uploadData.name === action.data.name) {
                            return {
                                ...uploadData,
                                updatedBy: undefined,
                                updatedAt: undefined,
                            } as UploadDto;
                        }

                        return uploadData;
                    }),
                },
                error: null
            };

        case "DELETE_FAILED":
            return {
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    isLoading: false,
                    isDeleting: false
                },
                error: action.error,
            };

        case "LOADING_DATA_FAILED":
            return {
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    isLoading: false
                },
                error: action.error
            };

        case "START_UPLOAD":
            return {
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    isUploading: true,
                    currentUploadGbc: action.currentUploadGbc,
                },
                error: null
            };

        case "START_UPLOAD_FAILED":
            return {
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    isUploading: false,
                },
                error: action.error
            };

        case "UPLOAD_SUCCESS":
            return {
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    isUploading: false,
                    currentUploadGbc: "",
                    data: prevState.dashboard.data.map((uploadData: UploadDto) => {
                        if (uploadData.name === action.data?.name) {
                            return {
                                ...uploadData,
                                fileName: action.data.fileName,
                                originalFileName: action.data.originalFileName,
                                updatedBy: action.data.updatedBy,
                                updatedAt: action.data.updatedAt,
                            } as UploadDto;
                        }
                        return uploadData;
                    }),
                },
                error: null
            };

        case "UPLOAD_FAILED":
            return {
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    isUploading: false,
                },
                error: action.error
            };
        default:
            return prevState;
    }
};

export default mainReducer;
