import {createGlobalStyle} from "styled-components/macro";

export default createGlobalStyle`

#root {
    display: grid;
    grid-template-rows: 48px auto;
    height: 100vh;
}

header {
    grid-row: 1/2;
    z-index: 1;
}

main {
    grid-row: 2/3;
    height: 100%;
    text-align: left;
    justify-content: center;
}

li {
 list-style-type: none;
}

`;
