import React from "react";

export interface DisplayErrorProps {
    name?: string;
    message?: string;
}

const DisplayError: React.FC<DisplayErrorProps> = (props) => {
    return (
        <div className='alert alert-danger' role='alert'>
            Ein Fehler ist aufgetreten.
            <p>                
                {props.name} {props.message}
                {props.children}
            </p>
        </div>);
}

export default DisplayError;
