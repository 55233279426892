import { UploadDto } from '../api/dtos';
import React from 'react';
import { UploadListItem } from './UploadListItem';

export type UploadListProps = {
    uploads: UploadDto[],
    onDeleteUpload: (upload: UploadDto) => void
    onUploadFile: (file: File, upload: UploadDto) => void
};

export const UploadList = ({ uploads, onDeleteUpload, onUploadFile }: UploadListProps) => {
    return (
        <>
            {uploads.map((upload, index) => {
                return (
                    <li key={index}>
                        <UploadListItem upload={upload} onDeleteUpload={onDeleteUpload} onUploadFile={onUploadFile}/>
                        <hr/>
                    </li>
                );
            })}
        </>
    );
};
