import React, { useContext } from 'react';
import { GreyButton } from './GreyButton';
import { UploadDto } from '../api/dtos';
import moment from 'moment';
import { AppContext } from '../state/Store';

type UploadListItemProps = {
    upload: UploadDto
    onDeleteUpload: (upload: UploadDto) => void
    onUploadFile: (file: File, upload: UploadDto) => void
};

export const UploadListItem = ({ upload, onDeleteUpload, onUploadFile }: UploadListItemProps) => {

    const [appState] = useContext(AppContext);

    return (<section className='departmentSection'>
            <div className='row'>
                <h2 className='col-md-10'>{upload.name}</h2>
                <div className='col-md-2'>
                    {(appState.dashboard.isUploading || appState.dashboard.isDeleting) ? (
                        <button className='btn btn-primary disabled' type='button' disabled={true}>
                            {(appState.dashboard.currentUploadGbc === upload.name) &&
                            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"/>
                            }
                            Hochladen
                        </button>
                    ) : (
                        <label className='btn btn-primary' htmlFor={`upload-${upload.name}`}>
                            Hochladen
                            <input
                                id={`upload-${upload.name}`}
                                type='file'
                                accept='.csv'
                                className='btn btn-secondary'
                                onChange={(event) => {
                                    const firstFile = event.target.files?.item(0);
                                    if (firstFile) {
                                        onUploadFile(firstFile, upload);
                                    }

                                    // we want to be able to choose the same file again
                                    event.target.value = '';
                                }}
                            />
                        </label>
                    )
                    }
                </div>
            </div>
            <div className='row'>
                <div className='col-md-10'>
                    {upload.originalFileName && upload.updatedAt && upload.updatedBy ? (
                        <p>
                            Letzter Upload:<br/>
                            {upload.originalFileName}<br/>
                            <small>
                                Am {moment(upload.updatedAt).format('lll')} von <em>{upload.updatedBy}</em>
                            </small>
                        </p>
                    ) : (
                        <p>Bisher kein Upload.</p>
                    )}
                </div>
                <div className='col-md-2'>
                    {upload.fileName && upload.updatedAt && upload.updatedBy && !appState.dashboard.isUploading && !appState.dashboard.isDeleting ? (
                        <GreyButton onClick={() => onDeleteUpload(upload)}>Löschen</GreyButton>
                    ) : (<button className='disabled' disabled={true}>Löschen</button>)
                    }
                </div>
            </div>
        </section>
    );
};
