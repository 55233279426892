import React, { createContext, useReducer } from 'react';
import mainReducer, { Action, AppState, initialState } from './Reducer';

const Store: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);
    return (
        <AppContext.Provider value={[state, dispatch]}>
            {children}
        </AppContext.Provider>
    );
};

export const AppContext = createContext<[
    state: AppState,
    dispatch: React.Dispatch<Action>,
]>([initialState, () => null]);

export default Store;